// export default [
//   {
//     title: 'Dashboard',
//     route: 'dashboard',
//     icon: 'PieChartIcon',
//   },
//   {
//     title: 'Registers',
//     icon: 'EditIcon',
//     children: [
//       {
//         title: 'Asset Register',
//         route: 'assets',
//         icon: 'ArchiveIcon',
//       },

//       {
//         title: 'Risk Register',
//         route: 'risk-register',
//         icon: 'AlertOctagonIcon',
//       },
//     ],
//   },

//   {
//     title: 'Tasks',
//     route: 'tasks',
//     icon: 'FileTextIcon',
//   },
//   {
//     title: "Vendors",
//     route: "vendors",
//     icon: "FilePlusIcon",
//   },
//   {
//     title: 'Control Frameworks',
//     route: 'standards',
//     icon: 'TrelloIcon',
//   },

//   {
//     title: 'Settings',
//     route: 'settings',
//     icon: 'SettingsIcon',
//   },

// ]

import store from "@/store";
const developmentRoutes = [
  // {
  //   title: "Global Dashboard",
  //   route: "globaldashboard",
  //   icon: "PieChartIcon",
  //   globalAllow: true,
  // },
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "PieChartIcon",
    // vendorAllow: true,
  },
  {
    title: "Standards",
    route: "standards",
    icon: "AwardIcon",
  },

  {
    title: "Registers",
    icon: "EditIcon",
    children: [
      {
        title: "Asset Register",
        route: "assets",
        icon: "ArchiveIcon",
      },

      {
        title: "Risk Register",
        route: "risk-register",
        icon: "AlertOctagonIcon",
      },
      {
        title: "Incident Register",
        route: "incidentregister",
        icon: "AlertOctagonIcon",
      },
      {
        title: "Legal Register",
        route: "legalregister",
        icon: "AlertOctagonIcon",
      },
    ],
  },

  {
    title: "Risk Management",
    icon: "GridIcon",
    children: [
      {
        title: "Risk Assessment",
        route: "riskassessment",
        icon: "AlertOctagonIcon",
      },
      {
        title: "Risk Register",
        route: "risk-register",
        icon: "AlertOctagonIcon",
      },
    ],
  },

  {
    title: "Assessments",
    icon: "ClipboardIcon",
    children: [
      {
        title: "Gap Assessment",
        route: "gapassessment",
        icon: "TrendingUpIcon",
      },

      {
        title: "Review Controls",
        route: "review-controls",
        icon: "ThumbsUpIcon",
      },
    ],
  },
  {
    title: "Tasks",
    route: "tasks",
    icon: "FilePlusIcon",
  },
  {
    title: "Policies",
    route: "policies",
    icon: "EditIcon",
  },
  {
    title: "Third Party",
    route: "vendors",
    icon: "FilePlusIcon",
  },

  {
    title: "ISMS",
    icon: "RefreshCwIcon",
    children: [
      {
        title: "Periodic Activities",
        route: "periodicactivities",
        icon: "AlertOctagonIcon",
      },
    ],
  },
  {
    header:'Settings',
  },
  {
    title: "General Settings",
    route: "accountsettings",
    icon: "SettingsIcon",
  },
  {
    title: "Password",
    route: "resetpasswordsettings",
    icon: "LockIcon",
  },
  
  {
    title: "Users Settings",
    route: "usersettings",
    icon: "UsersIcon",
  },
  {
    title: "Deleted Users",
    route: "deleted-users",
    icon: "UsersIcon",
  },
  {
    title: "Roles",
    route: "rolesettings",
    icon: "UserCheckIcon",
  },
  {
    title: "Departments",
    route: "departments",
    icon: "UsersIcon",
  },
  // {
  //   title: "Organization",
  //   route: "organizationsettings",
  //   icon: "TrelloIcon",
  // },
  
];

const productionRoutes = [
  // {
  //   title: "Global Dashboard",
  //   route: "globaldashboard",
  //   icon: "PieChartIcon",
  //   globalAllow: true,
  // },
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "PieChartIcon",
    // vendorAllow: true,
  },
  {
    title: "Standards",
    route: "standards",
    icon: "AwardIcon",
  },

  {
    title: "Registers",
    icon: "EditIcon",
    children: [
      {
        title: "Asset Register",
        route: "assets",
        icon: "ArchiveIcon",
      },

      {
        title: "Risk Register",
        route: "risk-register",
        icon: "AlertOctagonIcon",
      },
      {
        title: "Incident Register",
        route: "incidentregister",
        icon: "AlertOctagonIcon",
      },
      {
        title: "Legal Register",
        route: "legalregister",
        icon: "AlertOctagonIcon",
      },
    ],
  },

  {
    title: "Risk Management",
    icon: "GridIcon",
    children: [
      {
        title: "Risk Assessment",
        route: "riskassessment",
        icon: "AlertOctagonIcon",
      },
      {
        title: "Risk Register",
        route: "risk-register",
        icon: "AlertOctagonIcon",
      },
    ],
  },

  {
    title: "Assessments",
    icon: "ClipboardIcon",
    children: [
      {
        title: "Gap Assessment",
        route: "gapassessment",
        icon: "TrendingUpIcon",
      },
      {
        title: "Review Controls",
        route: "review-controls",
        icon: "ThumbsUpIcon",
      },
    ],
  },

  {
    title: "Tasks",
    route: "tasks",
    icon: "FilePlusIcon",
  },
  {
    title: "Policies",
    route: "policies",
    icon: "EditIcon",
  },
  {
    title: "Third Party",
    route: "vendors",
    icon: "FilePlusIcon",
  },

  {
    title: "ISMS",
    icon: "RefreshCwIcon",
    children: [
      {
        title: "Periodic Activities",
        route: "periodicactivities",
        icon: "AlertOctagonIcon",
      },
    ],
  },
  {
    header:'Settings',
  },
  {
    title: "General Settings",
    route: "accountsettings",
    icon: "SettingsIcon",
  },
  {
    title: "Password",
    route: "resetpasswordsettings",
    icon: "LockIcon",
  },
  {
    title: "Users Settings",
    route: "usersettings",
    icon: "UsersIcon",
  },
  {
    title: "Deleted Users",
    route: "deleted-users",
    icon: "UsersIcon",
  },
  {
    title: "Roles",
    route: "rolesettings",
    icon: "UserCheckIcon",
  },
  {
    title: "Departments",
    route: "departments",
    icon: "UsersIcon",
  },
  // {
  //   title: "Organization",
  //   route: "organizationsettings",
  //   icon: "TrelloIcon",
  // },
];

export default process.env.VUE_APP_ENVIRON &&
process.env.VUE_APP_ENVIRON === "DEVELOPMENT"
  ? developmentRoutes
  : productionRoutes;
