<template>
  <b-nav-item-dropdown
    class="dropdown-notification my-50 custom-menu-app-switcher"
    menu-class="custom-menu custom-menu-app-switcher px-1"
    left
  >
    <template #button-content>
      <b-button
        variant="transparant"
        class="btn-icon p-0"
        size="lg"
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        v-b-tooltip.hover.topright.v-info
        title="Secusy Apps"
        ><feather-icon icon="GridIcon" size="21" class="mr-50 text-primary"
      /></b-button>
    </template>

    <div
      class="row align-items-center justify-content-between"
      style="min-width: 23rem !important"
    >
      <div class="col-6 d-flex align-items-center my-1">
        <a :href="`${VUE_APP_APP_URL}app-switch`" target="_blank">
          <div
            class="text-center cursor-pointer custom-card mb-0 custom-padding"
          >
            <b-img
              src="@/assets/images/switch_logos/app_light.png"
              alt="logo"
              style="width: 110px"
              title="Secusy-App"
              v-if="skin == 'light'"
            />
            <b-img
              src="@/assets/images/switch_logos/app_dark.png"
              alt="logo"
              style="width: 110px"
              title="Secusy-App"
              v-else
            />

            <!-- <p class="mb-0 font-weight-bold">OneCsf</p> -->
          </div>
        </a>
      </div>
      <div
        class="col-6 d-flex align-items-center my-1"
        v-if="this.$store.state.app.currentWorkspaceModules.includes('Onecsf')"
      >
        <a :href="VUE_APP_ONECSF_URL" target="_blank">
          <div
            class="text-center cursor-pointer custom-card mb-0 custom-padding"
          >
            <b-img
              src="@/assets/images/switch_logos/onecsf_light.png"
              alt="logo"
              style="width: 110px"
              title="Onecsf"
              v-if="skin == 'light'"
            />
            <b-img
              src="@/assets/images/switch_logos/onecsf_dark.png"
              alt="logo"
              style="width: 110px"
              title="Onecsf"
              v-else
            />

            <!-- <p class="mb-0 font-weight-bold">OneCsf</p> -->
          </div>
        </a>
      </div>

      <div
        class="col-6 d-flex align-items-center my-1"
        v-if="
          this.$store.state.app.currentWorkspaceModules.includes(
            'Third Party Risk Management'
          )
        "
      >
        <a :href="`${VUE_APP_TPRM_URL}app-switch-landing`" target="_blank">
          <div
            class="text-center cursor-pointer custom-card mb-0 custom-padding"
          >
            <b-img
              src="@/assets/images/switch_logos/tprm_light.png"
              alt="logo"
              style="width: 110px"
              title="Third Party Risk Management"
              v-if="skin == 'light'"
            />
            <b-img
              src="@/assets/images/switch_logos/tprm_dark.png"
              alt="logo"
              style="width: 110px"
              title="Third Party Risk Management"
              v-else
            />
          </div>
        </a>
      </div>

      <div
        class="col-6 d-flex align-items-center my-1"
        v-if="
          this.$store.state.app.currentWorkspaceModules.includes(
            'Threat Management'
          )
        "
      >
        <a :href="`${VUE_APP_TM_URL}app-switch`" target="_blank">
          <div
            class="text-center cursor-pointer custom-card mb-0 custom-padding"
          >
            <b-img
              src="@/assets/images/switch_logos/tm_light.png"
              alt="logo"
              style="width: 110px"
              title="Threat Management"
              v-if="skin == 'light'"
            />
            <b-img
              src="@/assets/images/switch_logos/tm_dark.png"
              alt="logo"
              style="width: 110px"
              title="Threat Management"
              v-else
            />

            <!-- <p class="mb-0 font-weight-bold">TM</p> -->
          </div>
        </a>
      </div>
      <div
        class="col-6 d-flex align-items-center my-1"
        v-if="
          this.$store.state.app.currentWorkspaceModules.includes('Admin Portal')
        "
      >
        <a :href="VUE_APP_ADMIN_PORTAL_URL" target="_blank">
          <div
            class="text-center cursor-pointer custom-card mb-0 custom-padding"
            @click="c"
          >
            <b-img
              src="@/assets/images/switch_logos/admin_light.png"
              alt="logo"
              style="width: 110px"
              title="Admin Portal"
              v-if="skin == 'light'"
            />
            <b-img
              src="@/assets/images/switch_logos/admin_dark.png"
              alt="logo"
              style="width: 110px"
              title="Admin Portal"
              v-else
            />

            <!-- <p class="mb-0 font-weight-bold">PORTAL</p> -->
          </div>
        </a>
      </div>
      <!-- <div class="col-6 d-flex align-items-center my-1"  v-if="this.$store.state.app.enabled_modules.includes('GRC')">
          <a href="https://grc.valuesoc.com/" target="_blank">
          <div
            class="text-center cursor-pointer custom-card mb-0 custom-padding"
            @click="c"
          >
            <b-img
              src="@/assets/images/switch_logos/GRC.png"
              alt="logo"
              style="width: 110px"
              title="GRC"
            />
          </div>
          </a>
        </div> -->
    </div>
  </b-nav-item-dropdown>
</template>
    
    <script>
import {
  VBTooltip,
  BNavItemDropdown,
  BButton,
  BCard,
  BImg,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import { $themeConfig } from "@themeConfig";
import useAppConfig from "@core/app-config/useAppConfig";

// import FeatherIcon from "../../../../components/feather-icon/FeatherIcon.vue";

// import ResponseMixins from "../../../../../mixins/ResponseMixins";

export default {
  components: {
    BNavItemDropdown,

    BButton,

    BCard,

    BImg,

    // FeatherIcon,
  },

  directives: {
    "b-tooltip": VBTooltip,

    Ripple,
  },
  computed: {
    VUE_APP_ADMIN_PORTAL_URL() {
      return process.env.VUE_APP_ADMIN_PORTAL_URL;
    },
    VUE_APP_TM_URL() {
      return process.env.VUE_APP_TM_URL;
    },
    VUE_APP_TPRM_URL() {
      return process.env.VUE_APP_TPRM_URL;
    },
    VUE_APP_ONECSF_URL() {
      return process.env.VUE_APP_ONECSF_URL;
    },
    VUE_APP_APP_URL() {
      return process.env.VUE_APP_APP_URL;
    },
  },
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },

  //   mixins: [ResponseMixins],
};
</script>
    
    <style  scoped>
/* .custom-menu {
    
      max-width: 25rem !important;
    
    } */

.custom-menu.custom-menu-app-switcher {
  min-width: 23rem !important;
}

.custom-card {
  transition: all 0.2s linear;

  color: #7367f0;
}

.custom-card:hover {
  transform: scale(1.05);

  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  color: #fff;
  /* border: 1px solid #ccc; */
}

[dir] .card-body {
  padding: 0.6rem !important;
}

.custom-padding {
  border-radius: 5px;

  /* padding: 5px; */

  padding: 5px 12px;
}

.custom-padding p {
  font-size: 10px;
}
</style>