<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->

    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <AppSwitcher class="d-none d-lg-block" />
      <dark-Toggler class="d-none d-lg-block" />
      <notification-dropdown
        class="d-none d-lg-block"
        :notifications="notifications"
        :handleNotificationClick="handleNotificationClick"
        :handleMarkAllNotificationAsRead="handleMarkAllNotificationAsRead"
      />
      <div class="ml-1">
        <b-form-select
          v-model="selectWorkspace"
          @change="changeTenant(selectWorkspace)"
          :options="workspaceOptions"
          style="width: 240px"
          placeholder="Select Workspace"
        />
      </div>
    </div>

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <div
        class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex mr-1"
      >
        <!-- <dark-Toggler class="d-none d-lg-block" />
        <notification-dropdown
          class="d-none d-lg-block"
          :notifications="notifications"
          :handleNotificationClick="handleNotificationClick"
          :handleMarkAllNotificationAsRead="handleMarkAllNotificationAsRead"
        />
        <AppSwitcher class="d-none d-lg-block" />
        <div class="mr-0">
          <b-form-select
            v-model="selectWorkspace"
            @change="changeTenant(selectWorkspace)"
            :options="workspaceOptions"
            style="width: 240px"
            placeholder="Select Workspace"
          />
        </div> -->
      </div>
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ $store.state.app.user.firstname }}
              {{ $store.state.app.user.lastname }}
            </p>
            <span
              class="user-status d-flex flex-row align-items-center justify-content-end position-relative"
              v-if="$store.state.app.user['roles']"
            >
              <span v-if="$store.state.app.user.roles.length">{{
                $store.state.app.user.roles[0].display_name
              }}</span>
              <!-- <span v-else></span> -->
              <span>(</span>
              <template v-for="(value, key) in $store.state.app.workspaces">
                <span
                  :key="key"
                  style="max-width: 140px"
                  v-if="value.tenant_uid == $store.state.app.tenantId"
                >
                  <div class="text-container">
                    {{ value.name }}
                  </div>
                </span>
              </template>

              <span>)</span>
            </span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            class="badge-minimal"
            :text="avatarText($store.state.app.user.firstname)"
            badge-variant="success"
          />
        </template>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'tasks' }"
        >
          <feather-icon size="16" icon="CheckSquareIcon" class="mr-50" />
          <span>My Tasks</span>
        </b-dropdown-item>

        <!-- <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'settings' }"
        >
          <feather-icon icon="SettingsIcon" size="16" class="mr-50" />
          <span>Settings</span>
        </b-dropdown-item> -->

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="handleLogout()"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import vSelect from "vue-select";

import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BFormGroup,
  BFormSelect,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";

import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import AppSwitcher from "@/components/AppSwitcher.vue";
import NotificationDropdown from "@/components/NotificationsDropdown.vue";
import NotificationMixins from "@/mixins/NotificationMixins";
export default {
  data() {
    return {
      user: null,
      selectWorkspace: this.$store.state.app.currentWorkspace,
    };
  },
  mixins: [NotificationMixins],
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    vSelect,
    BFormGroup,
    // Navbar Components
    DarkToggler,
    AppSwitcher,
    BFormSelect,
    NotificationDropdown,
  },
  setup() {
    return {
      avatarText,
    };
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(["tenantid"]),
    workspaceOptions() {
      return this.$store.state.app.workspaces.map((wsp) => {
        return {
          text: wsp.name,
          value: wsp,
        };
      });
    },
    notifications: {
      get() {
        return this.$store.state.notification.allNotifications;
      },
    },
  },

  mounted() {
    // console.log(this.$store.state.app.token)
    // console.log(this.$store.state.app.currentWorkspace.tenant_uid)
    // console.log(this.$store.state.app.user.user_id)
    if (
      this.$store.state.app.token &&
      this.$store.state.app.token != null &&
      this.$store.state.app.currentWorkspace &&
      this.$store.state.app.currentWorkspace != null &&
      this.$store.state.app.currentWorkspace.tenant_uid &&
      this.$store.state.app.currentWorkspace.tenant_uid != null &&
      this.$store.state.app.user.user_id &&
      this.$store.state.app.user.user_id != null
    ) {
      this.$store.dispatch("notification/getAllNotifications");
      let channel = `grc_${this.$store.state.app.currentWorkspace.tenant_uid}_user_${this.$store.state.app.user.user_id}`;
      console.log("Socket Channel:", channel);
      this.sockets.subscribe(channel, (data) => {
        this.$store.dispatch("notification/getAllNotifications", data);
      });
    }
  },

  methods: {
    ...mapActions(["changetenant"]),
    handleLogout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.tenantId);
      this.$store.dispatch("notification/resetNotifications");
      this.$store
        .dispatch("app/logout")
        .then(() => {
          const url = "/login";
          // if (this.$store.state.app.user.is_staff) url = '/organizations'
          this.$router.push(url);
          console.log("Logged Out");
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleMarkAllNotificationAsRead() {
      this.$store
        .dispatch("notification/markAllNotificationsAsRead")
        .then((res) => {
          this.$store
            .dispatch("notification/getAllNotifications", {})
            .then(() => {
              this.$store.dispatch("notification/getNotifications", {});
            });
        })
        .catch((err) => {
          // this.handleError(err);
          console.log(err);
        });
    },

    handleNotificationClick(notification) {
      this.$store
        .dispatch("notification/getNotification", notification._id)
        .then((res) => {
          const newRoute = this.getRouteFromNotificationType(
            notification.data.target_type,
            notification.data.target_id
          );
          if (newRoute.includes(this.$route.path)) {
            this.$router.push(newRoute).then(() => {
              window.location.reload();
            });
          } else {
            this.$router.push(newRoute);
          }
          this.$store
            .dispatch("notification/getAllNotifications", {})
            .then(() => {
              this.$store.dispatch("notification/getNotifications", {
                page: 1,
              });
            });
        })
        .catch((err) => {
          // this.handleError(err);
          console.log(err);
        });
    },
    changeTenant(payload) {
      this.$store.dispatch("app/changetenant", payload).then(
        (response) => {
          this.$store
            .dispatch("notification/getAllNotifications", {})
            .then((res) => {
              window.location.reload();
            });
        },

        (error) => {
          console.log("Something went wrong");
        }
      );
    },
  },

  beforeDestroy() {
    let channel = `grc_${this.$store.state.app.currentWorkspace.tenant_uid}_user_${this.$store.state.app.user.user_id}`;
    this.sockets.unsubscribe(channel);
    console.log("Un-Subscribed from channel", channel);
  },
};
</script>
<style>
.text-container {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
