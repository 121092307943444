<template>
  <b-link
    v-if="notification && notification != null"
    @click="notificationClicked(notification)"
  >
    <div
      class="d-flex flex-row align-items-center justify-content-start py-75 px-1 border-bottom cursor-pointer"
      style="width: 100%"
    >
      <div class="d-flex align-items-center justify-content-center mr-1">
        <div
          class="d-flex align-items-center justify-content-center border"
          style="width: 49px; height: 49px; border-radius: 50%"
        >
          <feather-icon
            size="20"
            class="text-dark"
            :icon="getIconFromNotificationType(notification.data.target_type)"
          />
        </div>
      </div>

      <div
        class="w-100 d-flex flex-column align-items-start justify-content-center"
      >
        <div
          class="w-100 d-flex flex-row align-items-center justify-content-between mb-50"
        >
          <div class="d-flex flex-row align-items-center justify-content-start">
            <b-badge variant="primary" pill class="px-75"
              ><span class="font-weight-bold">{{
               notification.data.target_type
              }}</span></b-badge
            >
            <p class="text-muted mb-0 ml-1">
              {{ notification.created_at | moment }}
            </p>
          </div>

          <div class="d-flex algn-items-center justify-content-end">
            <div
              v-if="notification.read_at == null"
              style="width: 13px; height: 13px; border-radius: 50%"
              class="bg-success bg-lighten-1"
            ></div>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-start">
          <p class="mb-0 text-dark font-weight-bold">
            {{ notification.data.title }}
          </p>
        </div>
      </div>
    </div>
  </b-link>
</template>

<script>
import { BBadge, BLink } from "bootstrap-vue";
import moment from 'moment';

export default {
  components: {
    BBadge,
    BLink,
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
    handleNotificationClick: {
      type: Function,
      required: true,
    },
    closeNotificationDropdown: {
      type: Function,
      required: true,
    },
  },
   filters: {
    moment: function (date) {
      return moment(date).format("D-MMM-YY");
    },
  },

  methods: {
    notificationClicked(notification) {
      this.closeNotificationDropdown();
      this.handleNotificationClick(notification);
    },
    getIconFromNotificationType(type) {
      switch (type) {
        case "Task":
          return "EditIcon";
        default:
          return "MessageSquareIcon";
      }
    },

    //  getPathFromNotificationType(notification) {
    //   //VULN,TASK,SCAN,REPORT,BASELINE,DOMAINSCAN
    //   switch (notification.notification_type) {
    //     case "REPORT":
    //       return "/reports/";
    //     case "TASK":
    //       return "/";
    //     case "SCAN":
    //       return "/scans/";
    //     case "VULN":
    //       return "/assets/vulnerabilities";
    //     case "BASELINE":
    //       return `/assets/scan/:id`;
    //     case "DOMAINSCAN":
    //       return "BookIcon";
    //     default:
    //       return "BookIcon";
    //   }
    // },
    getTextFromNotificationType(type) {
      //VULN,TASK,SCAN,REPORT,BASELINE,DOMAINSCAN
      switch (type) {
        case `REPORT`:
          return "Report";
        case "TASK":
          return "Task";
        case "SCAN":
          return "Scan";
        case "VULN":
          return "Vulnerabilities";
        case "BASELINE":
          return "Asset Overview";
        case "DOMAINSCAN":
          return "Domain Scan";
        default:
          return "Unknown";
      }
    },
    
  },
};
</script>

<style></style>
